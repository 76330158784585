import React, { Component } from 'react';
import SubNav from './SubNav';
import $ from 'jquery';

class ExecutiveDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            title: "",
            bio: "",
            img: "",
            biopdf: ""
        }
    }

    componentDidMount() {
        let self = this;
        let url = `https://apipr.crownmediadev.com/api/executebio/${this.props.match.params.id}`;
        $.get(url, function (data) {
            self.setState(
                {
                    name: data.PersonName,
                    title: data.personTitle,
                    bio: data.Copy,
                    img: `https://apipr.crownmediadev.com/Medias/ExecutiveBios/listThumb/${data.PhotoFile}`,
                    biopdf: `https://apipr.crownmediadev.com/Medias/ExecutiveBios/Documents/${data.BioFile}`
                }
            );

        });
    }

    render() {
        return (
            <div className="">
                <SubNav currentActive={"EXECUTIVE LEADERSHIP"} />
                <div className="container executive-detail-container below-fixed-subnav below-about-subnav">
                    <div className="row">
                        <div className="col-xs 12 col-sm-6 col-md-4">
                            <img className="executive-detail-img" alt="" src={this.state.img} />
                            <a className="executive-download" target="_blank" rel="noopener noreferrer" href={this.state.biopdf} download={this.state.biopdf}>DOWNLOAD BIO</a>
                        </div>
                        <div className="col-xs 12 col-sm-6 col-md-8">
                            <h3>{this.state.name}</h3>
                            <h4>{this.state.title}</h4>
                            <div className="about-us-p" dangerouslySetInnerHTML={{ __html: this.state.bio }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExecutiveDetails;