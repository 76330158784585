import React, {Component} from 'react';

class News extends Component{
	render(){
		return(
			<h1>Press Room</h1>
		);
	}
}

export default News;