import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

class SocialLinks extends Component {
	render() {
		if (1 == 0) {
			return (
				<ul className="juicer-feed" data-feed-id="hallmarkchannel">
					<h1 className="referral" data-per="15" data-pages="1"> </h1>
				</ul>
			)
		}

		return (
			<Grid className="social-feed-container">
				<Row className="show-grid social">
					<Col md={4}>
						<div className="fb-page" data-tabs="timeline" data-href="https://www.facebook.com/hallmarkchannel" data-hide-cover="true"> </div>

					</Col>
					<Col md={4} id="twitter-feed">
						<a className="twitter-timeline" href="https://twitter.com/hallmarkchannel"> </a>

					</Col>
					<Col md={4} id="linkedin-feed">
						
						<script type="IN/FollowCompany" data-id="13967" data-counter="right"></script>
						<script type="IN/JYMBII" data-companyid="13967" data-format="inline"></script>
						<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6918183970450894849" height="475" width="325" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>


					</Col>
				</Row>
			</Grid>
		)
	}
}

export default SocialLinks;