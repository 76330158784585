import React, { Component } from 'react';

class ChannelLogo extends Component {
    render() {
        var dynamicClass = 'not-hoverable';
        if (this.props.hoverable === true) {
            dynamicClass = 'hoverable';
        }
        let logosrc = this.props.data.image;
        if (logosrc.indexOf("Podcast") > -1) {
            logosrc = '/images/HallmarkChannels_OfficialPodcastLogo.png';
        }
        return (
            <>
                <a href={this.props.data.url} target="_blank"> <img className={`channel-logo-new ${dynamicClass}`} src={logosrc} alt={this.props.data.title} />
                </a>
            </>
        );
    }
}

export default ChannelLogo;