import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

class Slideshow extends Component {
    constructor(props) {
        super(props);
        this.eachSlide = this.eachSlide.bind(this);
    }


    eachSlide(slide, i) {
        if (this.props.links === false) {
            return (
                <Carousel.Item key={i} id={i}>
                    <img target="_blank" alt={slide.ImagePath} src={`/images/${slide.ImagePath}`} />
                </Carousel.Item>
            );
        }
        return (
            <Carousel.Item key={i} id={i}>
                    <a target="_blank" href={slide.url}><img alt={slide.title} src={slide.image} /></a>
            </Carousel.Item>
        );
    }

    render() {
        let carouselClass = "crown-carousel";
        if (this.props.links === false) {
            carouselClass = "careers-carousel";
        }
        return (
            <div>
                <Carousel className={carouselClass}>
                    {this.props.data.map(this.eachSlide)}
                </Carousel>
            </div>
        );
    }

}

export default Slideshow;