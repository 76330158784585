import React, {Component} from 'react';

class NavItemWrapper extends Component{
	
	render(){
		if (this.props.img){
			return (
				<li role="presentation">
					<a className="nav-logo no-top-margin" href={this.props.link} style={{paddingTop: '5px'}}>
					{/* <a className="nav-logo no-top-margin" href={this.props.link}> */}
						<img className='crown-logo' alt="logo" src={this.props.img}/>
					</a>
				</li>
			)
		}
		return(
			<li role="presentation">
				<a className="external-link" target="_blank" rel="noopener noreferrer" href={this.props.link}>
					{this.props.title}
				</a>
			</li>
		)
	}
}

export default NavItemWrapper;