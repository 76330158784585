import React, {Component} from 'react';
// import $ from "jquery";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
class Newsfeed extends Component{
	constructor(props){
		super(props);
		this.state = {
			//events:[]
		}
		this.showNews = this.showNews.bind(this);
		this.formatDate = this.formatDate.bind(this);
	}

	showNews(data){
		let fileURL = data.fileURL;
		let date = this.formatDate(data.releaseDate);
		let headlineFeed;
		if (data.headline !== null) {
			headlineFeed = `${data.headline.toUpperCase()}...`;
		} else {
			headlineFeed = "";
		}
		return (
			<div className="newsFeed" key={data.id}>
				<h4 className="home-page-news-date">{date}</h4>
				<a target="_blank" rel="noopener noreferrer" className="newsfeed-event home-page-a" href={fileURL}><p>{headlineFeed}</p></a>
			</div>
		)
		
	}


	formatDate(date) {
		let firstDash = date.indexOf("-") + 1;
		let monthNum = parseInt(date.substring(firstDash,firstDash + 2), 10);
		let month = months[monthNum-1]
		let day = date.substring(firstDash + 3, firstDash + 5);
		let year = date.substring(0, firstDash-1);
		return `${month} ${day}, ${year}`
	}

	render(){
		return(
			<div className="col-sm-6">
				<a target="_blank" rel="noopener noreferrer" className="home-page-a" href="https://www.crownmediapress.com/hallmark-channel/news"><h2>RECENT NEWS</h2></a>
				<hr/>
				{this.props.data.map(this.showNews)}
				<a target="_blank" rel="noopener noreferrer" className="home-page-a" href="https://www.crownmediapress.com/hallmark-channel/news"><h4>VIEW MORE</h4></a>
			</div>
		)
	}
}

export default Newsfeed;