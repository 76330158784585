import { useState, useEffect } from "react";


export default function MakeHerMarkArrow({ resetSelectedLink }) {
    const [show, setShow] = useState(false);
    const [moveArrowFromFooter, setMoveArrowFromFooter] = useState(false)

    const controlNavbar = () => {
        if (window.scrollY > 250) {
            setShow(true)
        } else {
            setShow(false);
        }
    };

    const moveArrowPosition = () => {
        if (window.scrollY > 4300) {
            setMoveArrowFromFooter(true)
        } else {
            setMoveArrowFromFooter(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [show]);

    useEffect(() => {
        window.addEventListener('scroll', moveArrowPosition);

        return () => {
            window.removeEventListener('scroll', moveArrowPosition);
        };
    }, [moveArrowFromFooter]);


    const scrollToTop = () => {
        resetSelectedLink()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div className={`makehermark-arrow ${show ? 'arrow-show' : ''} ${moveArrowFromFooter ? 'arrow-bottom' : ''}`} onClick={scrollToTop}>
            <svg xmlns="http://www.w3.org/2000/svg" length="auto" height="70" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
            </svg>
        </div>
    )
}