import React, { Component } from "react";

class AboutFeaturedLogos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row1: [],
            row2: [],
            tve: []
        };
        this.showLogos = this.showLogos.bind(this);
        this.showLogosRowTwo = this.showLogosRowTwo.bind(this);
    }

    componentDidMount() {
        this.setState({
            row1: this.props.data.slice(0, 3),
            row2: this.props.data.slice(3, 6),
            tve: this.props.data.slice(6)
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            row1: nextProps.data.slice(0, 3),
            row2: nextProps.data.slice(3, 6),
            tve: nextProps.data.slice(6)
        });
    }

    showLogos(logo, i) {
        return (
            <div className="col-xs-6 col-sm-4" key={i}>
                <a target="_blank" href={logo.url}>
                    <img className="about-us-featured-logo" alt={logo.title} src={logo.image} />
                </a>
            </div>
        );
    }

    showLogosRowTwo(logo, i) {
        let logosrc = logo.image;
        let logoclass = 'about-us-featured-logo';

        if (logosrc.indexOf("Podcast") > -1) {
            logosrc = '/images/HallmarkChannels_OfficialPodcastLogo.png';
        }

        //if (logosrc.indexOf("Podcast") > -1 || logosrc.indexOf("tvelogo") > -1) {
        //    logoclass = 'about-us-custom-logo';
        //}

        return (
            <div className="col-xs-6 col-sm-4" key={i}>
                <a target="_blank" href={logo.url}>
                    <img className={logoclass} alt={logo.title} src={logosrc} />
                </a>

            </div>
        );
    }

    showTVE(logo, i) {
        let logosrc = logo.image;

        return (
            <div className="col-xs-offset-4 col-xs-4" key={i}>
                <a target="_blank" href={logo.url}>
                    <img className="about-us-featured-logo" alt={logo.title} src={logosrc} />
                </a>

            </div>
        );
    }

    render() {
        return (
            <div className="about-us-img-background">
                <div className="container">
                    <div className="about-us-header col-xs-12">
                        <div className="row featured-logo-row">
                            {this.state.row1.map(this.showLogos)}
                        </div>
                        <div className="row featured-logo-row">
                            {this.state.row2.map(this.showLogosRowTwo)}
                        </div>
                        <div className="row featured-logo-row">
                            {this.state.tve.map(this.showTVE)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutFeaturedLogos;