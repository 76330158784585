import React, { Component } from 'react';
import CareersSubNav from './CareersSubNav';
import LogoList from './LogoList';
import Slideshow from './Slideshow';
import { Link } from 'react-router-dom';


class CareersNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            careersArr: [],
        };
    }

    componentDidMount() {
        let self = this;
        $.get('https://corpcms.cminternalcontrol.com/api/Careers', function (data) {
            self.setState(
                {
                    careersArr: data
                }
            );
        });
    }

    render() {
        return (
            <div>
                <CareersSubNav />
                <div className="below-fixed-subnav">
                    <div className="container">
                        {this.state.careersArr.map(data =>
                            <>
                                <div className="about-us-header col-xs-12" key={data.id}>
                                    <img className='pageBannerImg' alt={data.title} src={data.banner} />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="container">
                        <div className="col-xs-12">
                            <div className="col-xs-12">
                                <div className="social-container">
                                    <div className="social-links">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/hallmarkchannel/"><i className="soc-icon fa fa-facebook fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hallmarkchannel?lang=en"><i className="soc-icon fa fa-twitter fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="http://www.pinterest.com/hallmarkchannel"><i className="soc-icon fa fa-pinterest fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hallmarkchannel/?hl=en"><i className="soc-icon fa fa-instagram fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/hallmark-channel"><i className="soc-icon fa fa-linkedin-square fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC5T-sc4bz1eAEgMIamLLV6w"><i className="soc-icon fa fa-youtube fa-2x"></i></a>
                                    </div>
                                </div>
                            </div>
                            {this.state.careersArr.map(data =>
                                <>
                                    <div className="homepage-div" key={data.id}>
                                        <p className="home-page-p">{data.description}</p>
                                    </div>
                                </>
                            )}
                            <div className="adoption-link-out-div">
                                <Link className="adoption-link-out" to="/careers/opportunities">SEE CURRENT CAREER OPPORTUNITIES</Link>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-xs-12">
                            {/* <Slideshow data={this.state.slides} links={false} /> */}
                        </div>
                        <div className="home-background">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">

                                        {/*<a className="home-page-a" rel="noopener noreferrer" href="/careers-new/opportunities"><h4 className="bold underline-animate">SEE CURRENT CAREER OPPORTUNITIES</h4></a>*/}
                                    </div>
                                    {/*<div className="col-sm-4 careers-linkedin-col">
                                        <script type="IN/FollowCompany" data-id="13967" data-counter="right"></script>
                                        <script type="IN/JYMBII" data-companyid="13967" data-format="inline"></script>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                        <div className='channel-logo-container'>
                            <LogoList />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CareersNew;