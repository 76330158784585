import React, {Component} from 'react';
import $ from 'jquery';


let displayed = [];

class ProgressBar extends Component{
	constructor(props){
		super(props)
		this.buildBar = this.buildBar.bind(this);
		this.showClickedYear = this.showClickedYear.bind(this);
	}

	buildBar(event, i){
		let ref= "element" + i
		if (i === 0){
			displayed.length = 0;
		}
		if (displayed.indexOf(event.year) >=0){
			return
		} else {
			displayed.push(event.year);
			return(
			<div onClick={this.showClickedYear} data-pointto={ref} className="progress-bar-segment" key={i}>
				<span className="progress-year">{event.year}</span>
			</div>
			)
		}
	}

	showClickedYear(event){
		let container = document.getElementById('tlContainer');
		if (container === null){
			container = document.getElementById('timelineContainer');
		}
		let id = event.currentTarget.dataset.pointto;
		let el = document.getElementById(id);
		if (el){
			let position = $(el).offset().left-350;
			$(container).stop().animate({
    			scrollLeft: position + $(container).scrollLeft()
  			}, 800);
		}
	}

	render(){
		const isMain = this.props;
		if (isMain)
			return <div className="progress-container">
				{this.props.events.map(this.buildBar)}
				</div>
			return null;
	}
}

export default ProgressBar;