import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import Home from './Home';
import About from './About';
import Networks from './Networks';
import Responsibility from './Responsibility';
import News from './News';
import Contact from './Contact';
import CareersNew from './components/CareersNew';
import Experience from './components/Experience';
import Internships from './components/Internships';
import ExecutiveBios from './ExecutiveBios';
import Milestones from './Milestones';
import Footer from './components/Footer';
import NavItemWrapper from './components/NavItemWrapper';
import ExecutiveDetails from './components/ExecutiveDetails';
import DiversityCareers from './components/DiversityCareers';
import HistoryCareers from './components/HistoryCareers';
import MakeHerMark from './MakeHerMark';
import MakeHerMarkBio from './MakeHerMarkBio';
import $ from "jquery";
import ContactPage from './ContactPage';
import ScrollToTop from './ScrollToTop';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            press: {
                title: "",
                url: ""
            }
        };
    }

    componentDidMount() {
        let self = this;
        $.get('https://apipr.crownmediadev.com/api/links?siteid=3112&nodeid=3106', function (data) {
            self.setState({
                press: {
                    title: data[0].LinkName,
                    url: data[0].URL
                }
            });
        });
    }

    render() {
        return (
            <div>
                <Router>
                    <ScrollToTop>
                        <div>
                            <div>
                                <Navbar className='main-color navbar-fixed-top' collapseOnSelect>
                                    <Navbar.Header>
                                        <Navbar.Toggle />
                                    </Navbar.Header>
                                    <Navbar.Collapse>
                                        <Nav className="navbar-center">
                                            <LinkContainer exact to="/networks"><NavItem>NETWORKS/SERVICES</NavItem></LinkContainer>
                                            <LinkContainer to="/about-us"><NavItem>ABOUT US</NavItem></LinkContainer>
                                            <LinkContainer to="/careers"><NavItem>CAREERS</NavItem></LinkContainer>
                                            <NavItemWrapper link={"/"} img={"/images/HallmarkMedia_Logo_White.png"} />
                                            <LinkContainer to="/diversity"><NavItem>DIVERSITY & INCLUSION</NavItem></LinkContainer>
                                            <NavItemWrapper link={"https://press.hallmarkmedia.com"} title={"PRESS"} />
                                            <LinkContainer to="/makehermark"><NavItem>MAKE HER MARK</NavItem></LinkContainer>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                            <div className="content">
                                <Route exact path="/" component={Home} />
                                <Route path="/networks" component={Networks} />
                                <Route exact path="/about-us" component={About} />
                                <Route exact path="/about-us/executives" component={ExecutiveBios} />
                                <Route path="/about-us/executives/:id" component={ExecutiveDetails} />
                                <Route exact path="/about-us/history" component={Milestones} />
                                <Route path="/about-us/contact" component={Contact} />
                                <Route exact path="/careers" component={CareersNew} />
                                <Route path="/careers/opportunities" component={Experience} />
                                <Route path="/careers/diversity" component={DiversityCareers} />
                                <Route path="/careers/internships" component={Internships} />
                                <Route path="/careers/history" component={HistoryCareers} />
                                <Route path="/diversity" component={Responsibility} />
                                <Route path="/news" component={News} />
                                <Route path="/contacts" component={ContactPage} />
                                <Route exact path="/makehermark" component={MakeHerMark} />
                                <Route path="/makehermark/bio" component={MakeHerMarkBio} />
                            </div>
                        </div>
                        <Footer />
                    </ScrollToTop>
                </Router>
            </div>
        );
    }
}

export default Main;