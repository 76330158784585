import React, { Component } from 'react';
import $ from "jquery";
import ChannelLogo from '../components/ChannelLogo';


class LogoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logos: []
        };

        this.showLogos = this.showLogos.bind(this);

    }

    componentDidMount() {
        var self = this;

        $.get('https://corpcms.cminternalcontrol.com/api/Networks', function (data) {
            data = data.sort(function (a, b) { return a.Sort - b.Sort });
            self.setState({
                logos: data
                
            });
        });
    }

    showLogos(logo, i) {
        return <ChannelLogo key={i + 10} data={logo} hoverable={false} />
    }

    render() {
        return (
            <>
                {this.state.logos.map(this.showLogos)}
            </>
        );
    }
}

export default LogoList;