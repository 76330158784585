import React, { Component } from 'react';
// import $ from "jquery";
import CareersSubNav from './CareersSubNav';

class Experience extends Component {

    render() {
        return (
            <div>
                <CareersSubNav currentActive={"OPPORTUNITIES"} />
                <div className="below-fixed-subnav">
                    <div className="container">
                        <div className="about-us-header col-xs-12">
                            <img className="pageBannerImg" alt="Careers at Hallmark Media" src='/images/CC24_HM_CorpWeb_Banner_Careers_1440x560_v1.jpg' />
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-xs-12">
                            <div className="about-us-header col-xs-12">
                                <div className="social-container">
                                    <div className="social-links">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/hallmarkchannel/"><i className="soc-icon fa fa-facebook fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hallmarkchannel?lang=en"><i className="soc-icon fa fa-twitter fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com/hallmarkchannel"><i className="soc-icon fa fa-pinterest fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hallmarkchannel/?hl=en"><i className="soc-icon fa fa-instagram fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/hallmark-channel"><i className="soc-icon fa fa-linkedin-square fa-2x"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC5T-sc4bz1eAEgMIamLLV6w"><i className="soc-icon fa fa-youtube fa-2x"></i></a>
                                    </div>
                                </div>
                            </div>
                            <p className="about-us-p">We offer a competitive benefits package including:</p>
                            <p className="about-us-p">• Medical/Dental/Vision • Company Paid Life Insurance and Disability • Long Term Care Insurance • Optional Life and Dependent Insurance • 401(k) Retirement Plan with Immediate Vesting • Tuition Reimbursement • Fitness Reimbursement • Matching Gifts Program • Employee Assistance Program • Flexible Spending Accounts • Parking and Commuter Transit Benefits • Vacation, Personal, Sick Leave • 10 Paid Holidays per year</p>
                        </div>
                    </div>
                    <div className="container">
                        <iframe title="careers" frameBorder={0} height={80} scrolling="yes" className='careers-frame' src="https://www.paycomonline.net/v4/ats/index.php?/jobs&clientkey=477B620764F4A7BADB21CD8BE2241D3A" />
                    </div>
                    <div className="container">
                        <div className="col-xs-12">
                            <p className="about-us-p italic">If you are an individual with a disability, a disabled veteran or a wounded warrior and you are unable or limited in your ability to access or use any one of Hallmark Media's electronic means of applying for jobs as a result of your disability, you may request a reasonable accommodation by contacting us via assistanceneeded@hallmarkmedia.com . Please note: Hallmark Media will respond only to requests for accommodations due to a disability.</p>
                        </div>
                        {/*<div className="col-xs-12">
                            <h2 className="connect-with-us-h">CONNECT WITH US</h2>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/hallmarkchannel/"><i className="soc-icon fa fa-facebook fa-2x"></i></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hallmarkchannel?lang=en"><i className="soc-icon fa fa-twitter fa-2x"></i></a>
                            <a target="_blank" rel="noopener noreferrer" href="http://www.pinterest.com/hallmarkchannel"><i className="soc-icon fa fa-pinterest fa-2x"></i></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hallmarkchannel/?hl=en"><i className="soc-icon fa fa-instagram fa-2x"></i></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/hallmark-channel"><i className="soc-icon fa fa-linkedin-square fa-2x"></i></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC5T-sc4bz1eAEgMIamLLV6w"><i className="soc-icon fa fa-youtube fa-2x"></i></a>
                        </div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Experience;