import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import sr from "./ScrollReveal";
// import ScrollReveal from 'scrollreveal';
import $ from "jquery";
import ProgressBar from './ProgressBar';
import Data from '../data/AboutUpdated';

class TimelineSlider extends Component {
    constructor(props) {
        super(props)
        this.showEvents = this.showEvents.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.placeElements = this.placeElements.bind(this);
    }

    componentDidMount() {
        let tlcontainer = document.getElementById('tlContainer');
        sr.reveal('.tl-li', { container: tlcontainer, opacity: 1, reset: true });
        if ($(window).width() > 880) {
            this.placeElements();
        }
    }

    scrollLeft() {
        let container = document.getElementById('tlContainer');
        $(container).stop().animate({ scrollLeft: '-=800' }, 800);
    }

    scrollRight() {
        let container = document.getElementById('tlContainer');
        $(container).stop().animate({ scrollLeft: '+=800' }, 800);
    }

    placeElements() {
        let divs = document.getElementsByClassName("tl-vertical-main-div");
        let maxHeight = 0;
        for (var i = 0; i < divs.length; i++) {
            let current = divs.item(i);
            if (current.offsetTop !== 355) {
                let offsetHeight = current.offsetHeight;
                let marginTop = 330 - offsetHeight;
                if (marginTop > 0) {
                    $(current).css('margin-top', marginTop);
                }
                if (offsetHeight > maxHeight) {
                    maxHeight = offsetHeight;
                }
            }
        }

        maxHeight = (maxHeight * 2) + 20;
    }

    showEvents(event, i) {
        let imgClass = "timeline-event-img";
        let id = "element" + i;
        let dynamicClass = "hc-milestone tl-horizontal-full-default-div auto-height";
        let logoClass = "tl-vertical-logo";
        if (event.logo.indexOf("CMFN") > -1) {
            dynamicClass = "cm-milestone tl-horizontal-full-default-div auto-height";
            logoClass += " tl-cm-logo";
        } else if (event.logo.indexOf("HMM") > -1) {
            dynamicClass = "hmm-milestone tl-horizontal-full-default-div auto-height";
        } else if (event.logo.indexOf("HMN") > -1) {
            dynamicClass = "hmn-milestone tl-horizontal-full-default-div auto-height";
        } else if (event.logo.indexOf("HD") > -1) {
            dynamicClass = "hd-milestone tl-horizontal-full-default-div auto-height";
        } else if (event.logo.indexOf("Publishing") > -1 || event.logo.indexOf("Bubbly") > -1) {
            dynamicClass = "hp-milestone tl-horizontal-full-default-div auto-height";
        } else if (event.logo.indexOf("tve") > -1) {
            dynamicClass = "hce-milestone tl-horizontal-full-default-div auto-height";
            logoClass += " tl-tve-logo";
        }

        if (event.img && event.isMoviePoster === true) {
            imgClass = imgClass += " movie-poster";
        } else if (event.img) {
            imgClass = imgClass += " short-img";
        }

        if (event.img) {
            return (
                <li className="timeline-event-li tl-li vertical-center-tl" ref={id} key={i} >
                    <img onLoad={this.sizeDefault} alt="timeline event" id={id} className={imgClass} src={event.img} />
                </li>
            );
        }
        return (
            <li key={i} ref={id} className="tl-li vertical-center-tl">
                <div className={dynamicClass}>
                    <div className="tl-vertical-logo-div">
                        <img alt="timeline-logo" id={id} className={logoClass} src={event.logo} />
                    </div>
                    <div className="tl-vertical-info-div">
                        <div className="horizontal-full-images-default-info">
                            <p className="horizontal-full-images-default-year">{event.year}</p>
                            <p className="horizontal-full-images-default-date">{event.date}</p>
                            <p className="horizontal-full-images-default-details" dangerouslySetInnerHTML={{ __html: event.event }} />
                        </div>
                    </div>
                </div>
            </li>
        );
    }

    render() {
        return (
            <div className="tl-container container-fluid">
                <ProgressBar isMain={true} events={Data.timeline} />
                <ol id="tlContainer" className={`tl tl-vertical`}>
                    {Data.timeline.map(this.showEvents)}
                </ol>
                <div className="arrows">
                    <div onClick={this.scrollLeft} className="tl-left-arrow">
                        <FontAwesome name={`arrow-circle-left tl-main-nav`} />
                    </div>
                    <div onClick={this.scrollRight} className='tl-right-arrow'>
                        <FontAwesome name={`arrow-circle-right tl-main-nav`} />
                    </div>
                </div>
            </div>
        )
    }
}

export default TimelineSlider;