import React, { Component } from 'react';
import SubNav from './components/SubNav';
import HorizontalTimeline from './components/HorizontalTimeline';
import Data from './data/AboutUpdated';

class Milestones extends Component {

    render() {
        return (
            <div>
                <SubNav currentActive={"OUR HISTORY"} />
                <HorizontalTimeline events={Data.timeline} />
            </div>
        );
    }
}

export default Milestones;