import React, { Component } from 'react';
import AboutFeaturedLogos from './components/AboutFeaturedLogos';
import SubNav from './components/SubNav';
import LogoList from './components/LogoList';
import $ from "jquery";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.aboutImage = this.aboutImage.bind(this);
        this.aboutDesc = this.aboutDesc.bind(this);
    }


    componentDidMount() {
        let self = this;
        $.get('https://corpcms.cminternalcontrol.com/api/aboutus', function (data) {
            self.setState({
                data: data
            });
        });
    }

    aboutImage(data, i) {
        let image = data.image;
        return (
            <div className="about-us-header col-xs-12" key={i}>
                <img className='pageBannerImg' alt='About Hallmark Media image' src={image} />
            </div>

        )
    }

    aboutDesc(data, i) {
        let desc = data.description;
        return (
            <div className="about-us-left-p" key={i}>
                {desc}
            </div>
        )
    }


    render() {

        return (
            <div>
                <SubNav />
                <div className="below-fixed-subnav below-about-subnav">
                    <div className="show-grid">
                        <div className="container">
                            {this.state.data.map(this.aboutImage)}
                        </div>
                        <div className='channel-logo-container'>
                            <LogoList />
                        </div>
                        <div className="container about-us-text-container">
                            <div className="col-xs-12">
                                {this.state.data.map(this.aboutDesc)}
                            </div> 
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default About;