import React from 'react';
import { Col, Row } from 'react-bootstrap';

const TitlewContent = ({ partnership }) => {

	const partnershipList = partnership.map(partner => {
		return (
			<>
				<a href={partner.url} target="_blank">
				<img alt={partner.title} className="partner-image" src={partner.image} />
				</a>
					<a className="partner-title-a" href={partner.url} target="_blank">
				<h3 className="title">{partner.title}</h3>
				</a>
				<p className="about-us-p">{partner.description}</p>
			</>
		)

	})

	return (
		<Row className="show-grid">
			<Col xs={12} md={12}>
				{partnershipList}
			</Col>
		</Row>
	)

}

export default TitlewContent;