import React, { Component } from 'react';
import CareersSubNav from './CareersSubNav';
import Responsibility from '../Responsibility';

class DiversityCareers extends Component {
    render() {
        return (
            <div>
                <CareersSubNav currentActive={"DIVERSITY"} />
                <div className="below-fixed-subnav">
                    <Responsibility />
                </div>
            </div>
        );
    }
}

export default DiversityCareers;