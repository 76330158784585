import React, { Component } from 'react';

class MakeHerMarkBio extends Component {
    render() {
        return (
            <div className="container executive-detail-container below-fixed-subnav below-about-subnav">
                <div className="row">
                    <div className="col-xs 12 col-sm-6 col-md-4">
                        <img className="executive-detail-img" alt="" src='/images/thumbnail_bf980eb146594631ad6557b9664ea9b2.jpg' />
                        {/* <a className="executive-download" target="_blank" rel="noopener noreferrer" href='' download=''>DOWNLOAD BIO</a> */}
                    </div>
                    <div className="col-xs 12 col-sm-6 col-md-8">
                        <h3>Ashley Williams</h3>
                        <h4>Make Her Mark Founder</h4>
                        <div className="about-us-p mhm-bio-container">
                            <p>
                                <span>
                                    Ashley Williams is a versatile actress in film, television and theatre. She has amassed a long list of credits including 200+ episodes of television, a dozen pilots and numerous feature films but “Sister Swap: A Hometown Holiday” and its companion movie “Sister Swap: Christmas in the City” mark the first time Williams acted with her real-life sister, Kimberly Williams-Paisley.  In addition to their on-screen roles the pair also served as executive producers on the two interconnected movies.
                                </span>
                            </p>
                            <p>
                                <span>
                                    Williams was recently seen in “Amber Brown,” a new series for Apple TV+ for writer/director Bonnie Hunt.  Previously, she starred in “The Jim Gaffigan Show” on Comedy Central, appeared alongside Jessica Chastain and Oscar Issac in the critically lauded A24 film A Most Violent Year and starred in Something Borrowed with Kate Hudson and John Krasinski for Warner Bros.  Other credits include J.C. Chandor's Oscar-nominatedMargin Call and Sequin Raze, a short film by Sarah Shapiro that won South by Southwest and became the series “UnREAL.”
                                </span>
                            </p>
                            <p>
                                <span>
                                    In recent years, Williams began producing movies for Hallmark Channel, debuting with “Love on a Limb,” in which she also starred and aired as part of the network's “Fall Harvest” programming event to record-breaking ratings. She loves working with the team at Hallmark who have empowered her creatively.  In addition to ongoing behind-the-camera work developing projects both to act in and to direct, Williams starred in the Hallmark Channel original movies “October Kiss,” “Christmas in Evergreen,” “Christmas in Evergreen: Letters to Santa,” “Christmas in Evergreen: Bells Are Ringing,” “Never Kiss a Man in a Christmas Sweater,” “Sister Swap: A Hometown Holiday,” “Sister Swap: Christmas in the City” and, most recently, “Two Tickets to Paradise.”  For Hallmark Mystery, she has starred in “Northern Lights of Christmas,” “Holiday Hearts” and last year's “Five More Minutes: Moments Like These.”
                                </span>
                            </p>
                            <p>
                                <span>
                                    Williams directed, wrote, produced and starred inMeats, a short film that premiered at the Sundance Film Festival where it won the Creative Coalition Spotlight Award and will be seen on Showtime as part of an upcoming series of short films.  Shot by Roman Vasyanov (Suicide Squad, Fury), it has played 30+ film festivals around the world.  She also wrote and stars in the short filmStud Boob which is in on the festival circuit with director Shaina Feinberg.  She's shadowing major television directors and developing a limited series with director Pamela Fryman based on a memoir calledKilling It.  She made her feature-length directorial debut with “Circle of Deception” for Lifetime this past year, and she is set to direct again soon for both Apple and Hallmark.
                                </span>
                            </p>
                            <p>
                                <span>
                                    As a teenager Williams played Meg Ryan's daughter, Dani Andropoulos, on the long-running daytime drama “As the World Turns.”  She starred alongside Mark Feuerstein in the NBC television series “Good Morning Miami” and in Lifetime's “Montana Sky” with John Corbett.  Williams also had a longtime recurring role on CBS' “How I Met Your Mother” as Victoria, “the baker that got away.”  She was so popular on the show that she won an online poll as to whom fans most wanted to see the as “the mother.”  Other credits include recurring roles on the Holly Hunter TNT series “Saving Grace,” SyFy's “Warehouse 13,” Lifetime's “Side Order of Life,” Showtime's “Huff” and CBS' “E-Ring,” as well as roles on “The Good Doctor,” “FBI,” “The New Adventures of Old Christine,” “CSI,” “Monk,” “The Mentalist,” “The Good Wife,” “Psych,” “Law & Order: SVU” and HBO's “Girls,” to name a few.
                                </span>
                            </p>
                            <p>
                                <span>
                                    Williams is the founder of Hallmark Media's women's directing mentorship program “Make Her Mark”, which creates opportunities for aspiring female filmmakers. The inaugural film of the program, “Dream Moms”, premiered on Hallmark Channel in 2023.
                                </span>
                            </p>
                            <p>
                                <span>
                                    Born in Westchester, New York, Williams studied at Boston University and RADA in London.  She was a member of the acting company at the Williamstown Theatre Festival.  Her first job out of drama school was understudying both Rachel Weisz and Gretchen Mol in the Off-Broadway play The Shape of Things, written and directed by Neil LaBute.  During the run, Williams performed both roles opposite Paul Rudd.  She starred with Wendie Malick in Off-Broadway'sBurleigh Grimes and starred on Broadway with Tom Skeritt in A Time to Kill, based on John Grisham's novel.
                                </span>
                            </p>
                            <p>
                                <span>
                                    With a natural skill for writing, Williams penned several personal essays about her miscarriage and her mother's battle with Alzheimer's disease for People and Medium.  Williams is also a birth doula, offering support to mothers during childbirth.  She splits her time between Los Angeles and New York with her husband, producer Neal Dodson, and their two young sons.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MakeHerMarkBio;