import React, { Component } from 'react';
// import NetworkData from './data/Networks.json';
import $ from "jquery";

class Networks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            networks: []
        };
        this.showNetworks = this.showNetworks.bind(this);
    }

    componentDidMount() {
        let self = this;
        $.get('https://corpcms.cminternalcontrol.com/api/Networks', function (data) {
            data = data.sort(function (a, b) { return a.Sort - b.Sort });
            self.setState({
                networks: data
            });
        });
    }

    showNetworks(logo, i) {
        return (
            <div key={i}>
                <div className="row">
                    <div className="col-sm-3 vcenter">
                        {/* <a href={logo.URL} target={logo.OpenTarget}> */}
                            <img 
                            alt={logo.title} 
                            className="network-page-logos" 
                            src={logo.image}
                             />
                        {/* </a> */}
                    </div>
                    <div className="network-page-paragraphs col-sm-9 vcenter">
                        <p className="networks-p">
                            {logo.description}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <hr className="networks-hr col-md-offset-1 col-md-10" />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="container network-page-container">
                {this.state.networks.map(this.showNetworks)}
            </div>
        );
    }
}

export default Networks;