import { useState, useEffect } from "react";


export default function MakeHerForm() {
    const [show, setShow] = useState(false);

    const controlNavbar = () => {
        if (window.scrollY > 0) {
            setShow(true)
        } else {
            setShow(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [show]);


    return (
        <div id='mhmApply' className={`container formstack-container make-her-mark-text-container ${show ? 'form-show' : ''}`}>
            <div className="col-xs-12">
                <div className="make-her-mark-p">
                    <h4 className='mhm-formstack mhm-h4'><b>SUBMISSION FORM</b></h4>
                    <p className='mhm-formstack'>To request technical support or report technical issues with your submitted application, please contact <a className="home-page-a-email" href="mailto:makehermark@hallmarkmedia.com">MakeHerMark@hallmarkmedia.com</a></p>
                    <div className="formstack-parent">
                        <iframe src='https://hallmarkcomms.formstack.com/forms/make_her_mark' title="Formstack Form" className='formstack-iframe' ></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}