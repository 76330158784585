import React, { Component } from 'react';
import diversity from './data/Diversity.json';
import { Row, Col } from 'react-bootstrap';
import TitlewContent from './components/TitlewContent';
import $, { data } from "jquery";

class Responsibility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			diversityInclusionArr: [],
			partnershipsArr: [],
			resourseCouncilArr: [],
			celebratesArr: []

		}
	}


	componentDidMount() {
		Promise.all([
			fetch('https://corpcms.cminternalcontrol.com/api/diversityandinclusion'),
			fetch('https://corpcms.cminternalcontrol.com/api/Partnerships'),
			fetch('https://corpcms.cminternalcontrol.com/api/EmployeeResourceCouncil'),
			fetch('https://corpcms.cminternalcontrol.com/api/hallmarkmediacelebrates'),
		])
			.then(response => Promise.all(response.map(r => r.json())))
			.then(([res1, res2, res3, res4]) => {
				this.setState({
					status: 'fetched',
					diversityInclusionArr: res1,
					partnershipsArr: res2,
					resourseCouncilArr: res3,
					celebratesArr: res4
				});

			})
	}


	render() {
		return (
			<div className="diversity-full-page">
				<div className='diversity-img-background'>
					<div className="container">
						<div className="row show-grid">
							{this.state.diversityInclusionArr.map(item =>
								<>
									<div className="col-xs-12 col-md-12" key={item.id}>
										<img className="pageBannerImg" alt={item.title} src={item.banner} />
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				<div className='diversity-outer-container'>
					<div className="container">
						<div className="row show-grid">
							<div className="col-xs-12 col-md-12">
								<Row className="show-grid">
									{this.state.diversityInclusionArr.map(item =>
										<Col xs={12} md={12} key={item.id}>
											<h2 className="">DIVERSITY & INCLUSION</h2>
											<hr />
											<p className="about-us-p">{item.description}</p>
										</Col>
									)}
								</Row>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row show-grid">
						<div className="col-xs-12 col-md-12">
							<h2>PARTNERSHIPS</h2>
							<hr />
							<TitlewContent partnership={this.state.partnershipsArr} />
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row show-grid">
						<div className="col-xs-12 col-md-12">
							<h2>EMPLOYEE RESOURCE COUNCIL</h2>
							<hr />
							<Row className="resource-container diversity-container" >
								{this.state.resourseCouncilArr.map(item =>
									<Col xs={12} md={12} className="resource-items items" key={item.id}>
										<div className='group-logo'>
											<img className='group-img' alt={item.title} src={item.image} />
										</div>
										<div className='group-desc'>
											{item.description}
										</div>
									</Col>
								)}
							</Row>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row show-grid">
						<div className="col-xs-12 col-md-12">
							<h2>HALLMARK MEDIA CELEBRATES</h2>
							<hr />
							<div className='subtext'>Hallmark Media celebrates many local and nationwide initiatives in the effort to spread awareness and commemorate the past, present, and future of the individuals that make up our workforce.</div>
							<Row className="celebrates-container diversity-container" >
								{this.state.celebratesArr.map(item => {
									return (
										<Col xs={12} md={12} className='celebrates-items items' key={item.id}>
											<div className='title'>{item.title}</div>
											<div className='group-logo'><img className='celebrates-img' alt={item.title} src={item.image} /></div>
										</Col>
									)
								})}
							</Row>
						</div>
					</div>
				</div>

			</div>
		);
	}

}

export default Responsibility;