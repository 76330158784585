import React, { Component } from 'react';
import SubNav from './components/SubNav';
import $ from 'jquery';


class ExecutiveBios extends Component {
    constructor(props) {
        super(props)
        this.showBios = this.showBios.bind(this);
        this.state = {
            execs: []
        }
    }

    // componentDidMount() {
    //     let self = this;
    //     $.get('https://apipr.crownmediadev.com/api/executebio?siteid=3112&nodeid=3103', function (data) {

    //         data.sort((a, b) => a.SortID - b.SortID)

    //         self.setState({ execs: data });
    //     });

    // }

    componentDidMount() {
        let self = this;
        $.get('https://corpcms.cminternalcontrol.com/api/ExecutiveLeaderships', function (data) {
            data.sort((a, b) => a.sort - b.sort)
            self.setState({ execs: data });
        });

    }

    showBios(bio, i) {
        return (
            <div key={i} className={`col-sm-4 col-md-3 executive-bio exec-id${bio.sort}`}>
                <div className="executive-image-div">
                    <a href={`/about-us/executives/${bio.sort}`} rel="noopener noreferrer">
                        <img
                            alt={bio.name}
                            className="executive-image"
                            src={bio.image}
                        />
                    </a>
                </div>
                <div className='executive-description'>
                    <div className={`executive-name exec_nameID${bio.sort}`}>
                        <a className="executive-name-a" href={`/about-us/executives/${bio.sort}`} rel="noopener noreferrer"><h4><strong>{bio.name}</strong></h4></a>
                    </div>
                    <div className={`executive-title exec_titleID${bio.sort}`}>
                        <h5>{bio.position}</h5>
                    </div>
                </div>
            </div>)
    }


    render() {
        let clearedBios = [];
        if (this.state.execs.length > 0) {
            let bios = [this.state.execs.map(this.showBios)];
            let count = 0;
            let bioArr = bios[0];
            for (let i = 0; i < bioArr.length; i++) {
                count++;
                clearedBios.push(bioArr[i]);
                if (count % 3 === 0) {
                    clearedBios.push(<div key={count * 10} className="clearfix visible-sm hidden-md hidden-lg"></div>);
                }
                if (count % 4 === 0) {
                    clearedBios.push(<div key={count * 10} className="clearfix hidden-sm visible-md visible-lg"></div>);
                }
            }
        }


        return (
            <div>
                <SubNav currentActive={"EXECUTIVE LEADERSHIP"} />
                <div className="executive-container container below-fixed-subnav below-about-subnav">
                    {clearedBios}
                </div>
            </div>
        );
    }
}

export default ExecutiveBios;