import React, { Component } from 'react';
import CareersSubNav from './CareersSubNav';
// import HorizontalFullImages from './HorizontalFullImages';
import HorizontalTimeline from './HorizontalTimeline';

class HistoryCareers extends Component {
    render() {
        return (
            <div>
                <CareersSubNav currentActive={"OUR HISTORY"} />
                <HorizontalTimeline />
            </div>
        );
    }
}

export default HistoryCareers;