import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slideshow from './components/Slideshow';
import SocialFeeds from './components/SocialFeeds';
import Newsfeed from './components/Newsfeed';
import LogoList from './components/LogoList';
import $ from "jquery";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            newsfeed: [],
        };
    }

    componentDidMount() {
        var self = this;
        $.get('https://corpcms.cminternalcontrol.com/api/HomeSlide', function (data) {
            data = data.sort(function (a, b) { return a.Sort - b.Sort });
            self.setState({
                slides: data
            });
        });

        $.get('https://presscms.cminternalcontrol.com/api/corpsite', function (data) {
            const latestNewsData = data.sort((a, b) => new Date(a.releaseDate) > new Date(b.releaseDate) ? -1 : 0).slice(0, 3)
            self.setState({ newsfeed: latestNewsData });
        });

    }

    render() {
        return (
            <div>
                <div>
                    <Slideshow data={this.state.slides} />
                </div>
                <div className='channel-logo-container'>
                    <LogoList />
                </div>
                <div className="home-background">
                    <div className="container">
                        <div className="row">
                            <Newsfeed data={this.state.newsfeed} />
                            <div className="col-sm-6">
                                <Link className="home-page-a" to='/careers'><h2>CAREERS</h2></Link>
                                <hr />
                                <h3>JOIN THE HALLMARK FAMILY</h3>
                                <p className="home-page-p">We at Hallmark Media encourage job seekers to bring their talent, a fresh perspective, innovation and creativity. To that end, we have shaped an employee community and work environment that has contributed richly to our growth and success.</p>
                                <p className="home-page-p">Hallmark Media is an Equal Opportunity Employer.</p>
                                <Link className="home-page-a" to="/careers/opportunities"><h4>APPLY TO OPEN POSITIONS</h4></Link>

                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <h2 className="connect-with-us-h">CONNECT WITH US</h2>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/hallmarkchannel/"><i className="soc-icon fa fa-facebook fa-2x"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hallmarkchannel?lang=en"><i className="soc-icon fa fa-twitter fa-2x"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com/hallmarkchannel"><i className="soc-icon fa fa-pinterest fa-2x"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hallmarkchannel/?hl=en"><i className="soc-icon fa fa-instagram fa-2x"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/hallmark-channel"><i className="soc-icon fa fa-linkedin-square fa-2x"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC5T-sc4bz1eAEgMIamLLV6w"><i className="soc-icon fa fa-youtube fa-2x"></i></a>
                                <hr />
                            </div>
                            <SocialFeeds />
                            <hr className="social-divider-hr" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
